.gslide-description {
    .gdesc-inner{
        .gslide-desc{
            font-family: $font-family-base;
            font-size: 15px;
        }
        .gslide-title {
            font-family: $font-family-base;
            margin-bottom: 5px;
            font-size: 17px;
            font-weight: $font-weight-medium;
        }
        
    }
}
