//
// page-title.scss
//

.page-title-box {
    background-image: url('../images/home/page-title.png');
    background-size: cover;
    background-color: darken($primary, 5%);
    position: relative;
    padding-top: 225px;
    padding-bottom: 110px;
    background-position: center;
}

.shape {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;

    &>svg {
        width: 100%;
        height: auto;
    }
}

.breadcrumb {
    letter-spacing: 0.5px;
    text-align: center;

    .breadcrumb-item {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;

        &::before {
            content: "\F0142";
            font-size: 14px;
            color: $white;
            font-family: "Material Design Icons";
            padding-left: 8px;
        }

        &:first-child {
            &::before {
                content: "";
            }
        }

        a {
            color: $white;
            transition: all 0.5s ease;

            &:hover {
                color: $gray-400;
            }
        }
    }
}


/*****************dark-mode******************/

body[data-layout-mode='dark'] {
    .page-title-box {
        background-color: $gray-dark-100;
    }

    .shape {
        &>svg {
            path {
                fill: $gray-dark-200;
            }
        }
    }

    .shape-light {
        &>svg {
            path {
                fill: $gray-dark-300;
            }
        }
    }

    .job-pagination {
        .page-item {
            .page-link {
                background-color: $gray-dark-400;
                border-color: $gray-dark-400;
            }

            &.active {
                .page-link {
                    background-color: $primary;
                    border-color: $primary;
                }
            }
        }
    }
}