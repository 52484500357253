//
//select.scss
//

.choices__list--multiple .choices__item {
    background-color: $primary;
    border-color: $primary;
    margin-bottom: 0;
}

.choices__list--dropdown {
    z-index: 2;
    border-color: darken($gray-400, 2.5%);
    font-weight: 500;
    max-height: 280px;
    .choices__item--selectable {
        padding: 0;
        line-height: 43px;
        min-height: 44px;
        padding-left: 15px;
        padding-right: 29px;
        text-align: left;
        font-size: 15px;

        &::after {
            display: none;
        }

        &.is-highlighted {
            color: $primary;
            background-color: $light;
        }
    }
}

.choices__input {
    background-color: transparent;
    margin-bottom: 0;
}

.choices__inner {
    color: $dark;
    background-color: $white;
    padding: 6px 10px;
    min-height: 39px;
    border: 1px solid;
    border-color: darken($gray-400, 2.5%);
    font-weight: 500;

    &:focus {
        border: 1px solid;
        border-color: darken($gray-400, 2.5%);
    }
}

.choices[data-type*=select-one] {
    .choices__input {
        background-color: transparent;
        border: 1px solid $primary;
        margin: 10px;
        border-radius: 5px;
        width: 90%;
    }
    &::after {
        border: 0;
        right: 11.5px;
        left: auto;
        content: "\F0140";
        font-family: 'Material Design Icons';
        top: 35%;
        right: 28px;
    }

    &.is-open {
        .choices__list--dropdown,
        .choices__inner {
            box-shadow: $box-shadow;
            border-color: darken($gray-400, 2.5%);
        }

        &::after {
            margin-top: -2.5px;
        }
    }
}

.is-focused .choices__inner,
.is-open .choices__inner {
    border: 1px solid;
    border-color: darken($gray-400, 2.5%);
}

.selection-widget {
    .form-select {
        padding: 8px 20px;
        color: $gray-700;
        border-color: darken($gray-400, 2.5%);

        &:focus {
            box-shadow: none;
        }

        option {
            padding: 20px;
        }
    }
}

/*****************dark-mode******************/

body[data-layout-mode='dark'] {

    .choices__inner,
    .choices__list--dropdown,
    .choices[data-type*=select-one] .choices__input,
    .choices__input {
        background-color: $gray-dark-300;
        border-color: $gray-dark-400;
        color: $gray-100;

        &:focus {
            border-color: $gray-dark-400;
        }
    }

    .choices[data-type*=select-one] {
        &.is-open {

            .choices__list--dropdown,
            .choices__inner {
                border-color: $gray-dark-400;
            }
        }
    }

    .choices__list--dropdown {
        .choices__item--selectable {
            &.is-highlighted {
                background-color: $gray-dark-400;
                color: $primary;
            }
        }
    }
}