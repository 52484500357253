//
//form.scss
//

.form-control,
.form-select {
  padding: 10px;
  font-size: 14px;
  border-color: darken($gray-400, 2.5%);
  font-weight: 500;
  &::-webkit-input-placeholder {
    color: $gray-500;
    font-size: 13px;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $gray-500;
    font-size: 13px;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $gray-500;
    font-size: 13px;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $gray-500;
    font-size: 13px;
  }
}

[type="search"]::-webkit-search-cancel-button,
.form-check-label, .form-check-input {
  cursor: pointer;
}

/*****************dark-mode******************/

body[data-layout-mode="dark"] {
  .form-control, .form-check-input {
    color: $gray-100;
    border-color: $gray-dark-400;
    background-color: $gray-dark-300;
    &:checked {
      background-color: $primary;
      border-color: $primary;
    }
  }
}