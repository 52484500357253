//
//faq.scss
//

.faq-menu {
    &.nav-pills {
        .nav-item {
            .nav-link {
                color: $dark;
                font-weight: 500;
                border-bottom: 2px solid transparent;
                border-radius: 0;
                font-size: 17px;

                &.active {
                    color: $primary;
                    background-color: transparent !important;
                    border-bottom-color: $primary;
                }
            }
        }
    }
}

.faq-box {
    .accordion-item {
        .accordion-header {
            .accordion-button {
                &:not(.collapsed) {
                    color: $primary;
                    box-shadow: none;
                }
            }
        }
    }
}


/*****************dark-mode******************/

body[data-layout-mode='dark'] {
    .faq-menu {
        .nav-item {
            .nav-link {
                color: $gray-100;

                &.active {
                    color: $primary;
                }
            }
        }
    }

    .accordion-button {
        color: $gray-200;
    }
}